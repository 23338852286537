<template>
  <main>
    <div id="aside-wrap-container" v-if="!loading">

      <section class="dgrid-body flow">
        <div id="title-wrapper" class="view-title">
          <h4 class="capitalize-first">{{dateTime}}</h4>
          <h2>{{item.title}}</h2>        
        </div>
        <p class="text--subtitle">{{ subtitle }}</p>
        <img class="rounded" :src="media">
      </section>
      
      <aside :class="{'bordered-left': !mobile}">
        <div id="map-container" v-if="coordinates" class="bordered-bottom" :class="{'fixed-lateral-drawer': mobile, 'opened': mapOpen, mobile:mobile}">

          <div v-if="mobile" class="map-button map-close-button cover selected" @click="toggleMap">
            <base-icon name="arrow-move-right" color-fill="white"/>
          </div>

          <interface-map
            :markers="markers"
            :zoom="14"
            :center="coordinates"
            :interactive="true"
            :interactive-markers="false" />

        </div>
        <div id="info" :class="mobile ? 'mobile bordered-top bordered-bottom' : 'full-width'" >
          <div :class="{'dgrid-body': mobile}" class="stack-horizontal" data-justify="spaced">
            <div class="flow" >
              <div v-for="i in info">
                <p class="text--body1-medium capitalize">{{ i.label }}</p>
                <p class="text--body1 capitalize-first">{{ i.value }}</p>
              </div>
              <div v-if="mobile" class="capitalize-first stack-horizontal" @click="toggleMap">{{$t('show_map')}}<base-icon name="arrow-right"/></div>
            </div>
            <img v-if="region" :src="region">
          </div>
        </div>
      </aside>
      
      <section id="description" class="dgrid-body text--body1">
        <p v-html="parsedDescription" class="marked"></p>
        <div class="view-bottom-margin"></div>
      </section>
    
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex';
import InterfaceMap from '@components/InterfaceMap.vue';
import cmsItem from '@mixins/cmsItem.js';
import utils from '@js/utils.js'
import * as marked from 'marked';

export default {
  name: 'Event',
  components: { InterfaceMap },
  mixins: [ cmsItem ],
  props: {
    // id: { //route prop
    //   type: String
    // },
    slug: { //route prop
      type: String,
      required: true
    }
  },
  data() {
    return {
      itemType: 'events',
      mapOpen: false
    }
  },
  computed: {
    ...mapState('interface', ['mobile']),
    date() {
      let date = new Date(this.item.date);
      let rtn = Intl.DateTimeFormat(this.$i18n.locale, { dateStyle: 'full'}).format(date);
      return rtn
    },
    startTime() {
      let start = new Date(this.item.time_start);
      if (!isNaN(start)) {
        let rtn = Intl.DateTimeFormat(this.$i18n.locale, { timeStyle: 'short'}).format(start);
        return rtn;
      }
    },
    endTime() {
      let end = new Date(this.item.time_end);
      if (!isNaN(end)) {
        let rtn = Intl.DateTimeFormat(this.$i18n.locale, { timeStyle: 'short'}).format(end);
        return rtn;
      }
    },
    dateTime() {
      let rtn = this.date;
      if (this.startTime) {
        rtn += ' '+this.$t('from_time')+' '+this.startTime;
        if (this.endTime) {
          rtn += ' '+this.$t('to_time')+' '+this.endTime;
        }
      }
      return rtn;
    },
    media() {
      return utils.parseMediaUrl(this.item.media_file);
    },
    info() {
      return [
        {
          label: this.$t('where'),
          value: this.location
        },
        {
          label: this.$t('when'),
          value: this.dateTime
        }
      ]
    },
    location() {
      let rtn = '';
      if (this.item.location) rtn += this.item.location+', ';
      if (this.item.address) rtn += this.item.address+', ';
      rtn += this.item.town;
      return rtn;
    },
    subtitle() {
      return this.item.subtitle;
    },
    coordinates() {
      return this.item && this.item.longitude && this.item.latitude ? [this.item.longitude, this.item.latitude] : undefined;
    },
    markers() {
      return this.item ? [{
        geodata_latitude: this.item.latitude,
        geodata_longitude: this.item.longitude,
        id: this.item.id
      }] : [];
    },
    region() {
      if (this.item.region && this.item.region.length) return utils.getRegionSvgByName(this.item.region);
    },
    parsedDescription() {
      return marked.parse(this.item.description);
    }
  },
  methods: {
    toggleMap() {
      this.mapOpen = !this.mapOpen;
    }
  },
  mounted() {
    // console.log(this.item)
  }
}
</script>

<style lang="scss" src="@css/views/ViewEvent.scss"></style>